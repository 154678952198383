<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.mandatory"
          label="Require Participant Info in checkout?"
          :items="mandatoryOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <hr />

        <h3 class="mt-4 mb-4">
          Normally Required Fields:
        </h3>
        <v-select
          v-model="form.first_name"
          :disabled="!form.mandatory"
          label="First Name"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.last_name"
          :disabled="!form.mandatory"
          label="Last Name"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.phone"
          :disabled="!form.mandatory"
          label="Phone"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.email"
          :disabled="!form.mandatory"
          label="Email"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.gender"
          :disabled="!form.mandatory"
          label="Gender"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <p>Note: Generally, <b>First name</b>, <b>Last name</b>, <b>email</b>, <b>phone</b>, <b>gender</b> are required not matter how you set. You should set them to required. Since PWC will be required to supply them all the time.</p>

        <hr />

        <h3 class="mt-4 mb-4">
          Other Fields:
        </h3>
        <v-select
          v-model="form.birthday"
          :disabled="!form.mandatory"
          label="Birthday"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.relationship"
          :disabled="!form.mandatory"
          label="Relationship with main acc holder"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.id_no"
          :disabled="!form.mandatory"
          label="ID number (ID/Passport)"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.employee_id"
          :disabled="!form.mandatory"
          label="Employee id (normally set optional / NA)"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.remarks"
          :disabled="!form.mandatory"
          label="Remarks, special request (normally set optional / NA)"
          :items="ruleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, defValue) => ({
      [name]: props.resource ? props.resource[name] : defValue,
    })

    const initialForm = {
      ...field('mandatory', 1),
      ...field('first_name', 1),
      ...field('last_name', 1),
      ...field('phone', 1),
      ...field('gender', 1),
      ...field('email', 1),
      ...field('birthday', 1),
      ...field('relationship', 1),
      ...field('id_no', -1),
      ...field('employee_id', -1),
      ...field('remarks', -1),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        emit('submit', form.value)
      }
    }

    const mandatoryOptions = [
      { title: 'Required', value: 1 },
      { title: 'Not Required', value: 0 },
    ]

    const ruleOptions = [
      { title: 'Required', value: 1 },
      { title: 'Optional (Visible + Not required)', value: 0 },
      { title: 'Not Applicable (Invisible)', value: -1 },
    ]

    return {
      form,
      formElem,

      validate,
      required,

      mandatoryOptions,
      ruleOptions,
    }
  },
}
</script>
