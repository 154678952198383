var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[(_vm.formReady)?_c('participant-rule-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadParticipantRules(); _vm.sidebarActive = false}},model:{value:(_vm.sidebarActive),callback:function ($$v) {_vm.sidebarActive=$$v},expression:"sidebarActive"}}):_vm._e(),_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.totalLocal),function(total){return _c('v-col',{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])])])],1)],1)}),1),_c('v-card',[_c('v-card-title',[_vm._v(" Search & Filter ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addNewResoure.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Rule")])],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.participantRuleListTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.first_name)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.first_name)))])])]}},{key:"item.mandatory",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.mandatory)}},[_c('span',[_vm._v(_vm._s(item.mandatory ? 'Must be filled' : 'No participant'))])])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.last_name)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.last_name)))])])]}},{key:"item.id_no",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.id_no)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.id_no)))])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.phone)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.phone)))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.email)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.email)))])])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.gender)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.gender)))])])]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.birthday)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.birthday)))])])]}},{key:"item.relationship",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.relationship)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.relationship)))])])]}},{key:"item.employee_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.employee_id)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.employee_id)))])])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.remarks)}},[_c('span',[_vm._v(_vm._s(_vm.getText(item.remarks)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setParticipantRule(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }