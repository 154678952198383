<template>
  <div id="user-list">
    <!-- app drawer -->
    <participant-rule-aside
      v-if="formReady"
      v-model="sidebarActive"
      :resource="resource"
      @changed="loadParticipantRules(); sidebarActive = false"
    ></participant-rule-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <v-row>
        <v-col cols="6"></v-col>
        <v-col
          cols="6"
          class="text-right"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="addNewResoure"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Rule</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="participantRuleListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <template #[`item.first_name`]="{item}">
          <v-chip :color="getColor(item.first_name)">
            <span>{{ getText(item.first_name) }}</span>
          </v-chip>
        </template>

        <template #[`item.mandatory`]="{item}">
          <v-chip :color="getColor(item.mandatory)">
            <span>{{ item.mandatory ? 'Must be filled' : 'No participant' }}</span>
          </v-chip>
        </template>

        <template #[`item.last_name`]="{item}">
          <v-chip :color="getColor(item.last_name)">
            <span>{{ getText(item.last_name) }}</span>
          </v-chip>
        </template>

        <template #[`item.id_no`]="{item}">
          <v-chip :color="getColor(item.id_no)">
            <span>{{ getText(item.id_no) }}</span>
          </v-chip>
        </template>

        <template #[`item.phone`]="{item}">
          <v-chip :color="getColor(item.phone)">
            <span>{{ getText(item.phone) }}</span>
          </v-chip>
        </template>

        <template #[`item.email`]="{item}">
          <v-chip :color="getColor(item.email)">
            <span>{{ getText(item.email) }}</span>
          </v-chip>
        </template>

        <template #[`item.gender`]="{item}">
          <v-chip :color="getColor(item.gender)">
            <span>{{ getText(item.gender) }}</span>
          </v-chip>
        </template>

        <template #[`item.birthday`]="{item}">
          <v-chip :color="getColor(item.birthday)">
            <span>{{ getText(item.birthday) }}</span>
          </v-chip>
        </template>

        <template #[`item.relationship`]="{item}">
          <v-chip :color="getColor(item.relationship)">
            <span>{{ getText(item.relationship) }}</span>
          </v-chip>
        </template>

        <template #[`item.employee_id`]="{item}">
          <v-chip :color="getColor(item.employee_id)">
            <span>{{ getText(item.employee_id) }}</span>
          </v-chip>
        </template>

        <template #[`item.remarks`]="{item}">
          <v-chip :color="getColor(item.remarks)">
            <span>{{ getText(item.remarks) }}</span>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setParticipantRule(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
mdiDotsVertical,
mdiPencil,
mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'

import { t } from '@/plugins/i18n'
import ParticipantRuleAside from '../participant-rule-resource/ParticipantRuleAside.vue'

import useParticipantRuleList from './useParticipantRuleList'

export default {
  components: {
    ParticipantRuleAside,
  },
  setup() {
    const {
      participantRuleListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      getColor,
      getText,

      loadParticipantRules,
      updateAllParticipantRules,
    } = useParticipantRuleList()

    const sidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const rootParticipantRuleName = participantRules => {
      const participantRule = participantRules.find(c => !c.parent_id)

      return participantRule ? t(participantRule.name) : ''
    }

    // const participantRuleegoriesOptions = commonStore.state.participantRuleegories.map(participantRule => ({ title: field(participantRule.name), value: participantRule.id }))

    const setParticipantRule = participantRule => {
      resource.value = participantRule
      sidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      sidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      participantRuleListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      sidebarActive,
      formReady,
      resource,
      selectedRows,
      avatarText,
      loadParticipantRules,
      setParticipantRule,
      addNewResoure,
      updateAllParticipantRules,
      rootParticipantRuleName,

      t,
      getColor,
      getText,

      // icons
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
